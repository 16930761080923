import { Component, OnInit } from '@angular/core';
import { GlobalFunctionsService } from '../../service/global-functions.service';

@Component({
  selector: 'app-email-log',
  templateUrl: './email-log.component.html',
  styleUrls: ['./email-log.component.scss']
})
export class EmailLogComponent implements OnInit {

  constructor(public _f: GlobalFunctionsService) { }

  ngOnInit() {
  }

}
