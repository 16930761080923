import { BrowserModule } from '@angular/platform-browser';
import { Injectable, NgModule, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { RequestOptions, Request, Headers } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { HttpModule } from '@angular/http';

import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';


import { UploadFileService } from '../service/upload/upload-file.service';
import { UploadFile } from '../service/upload/upload-file';

import * as firebase from 'firebase/app';

import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

import { environment } from '../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class GlobalFunctionsService {

  //global
  public page_dashboard: boolean = false;
  public page_notifications: boolean = false;
  public page_loyalty_card: boolean = false;
  public page_latest_offers: boolean = false;
  public page_how_to_find_us: boolean = false;
  public page_bookings: boolean = false;
  public page_about_us: boolean = false;
  public page_get_in_touch: boolean = false;
  public page_email_log: boolean = false;
  public page_QR: boolean = false;

  public aboutUsText: Observable<any>;
  public location: Observable<any>;
  public savedMessage: string = '';
  //latest offers
  public title = 'JustBeLoyal-Admin';
  public ref: AngularFireStorageReference;
  public task: AngularFireUploadTask;
  public uploadState: Observable<any>;
  public uploadProgress: Observable<number>;
  public _global_items_subscription: Subscription;
  public _global_items_Email: Subscription;
  public _global_items_Bookings: Subscription;


  public _global_items_loyaltyCards: Subscription;
  public EmailLog: any = null;
  public BookingsLog: any = null;

  public _items_loyaltyCards: any = null;
  public loyaltyCards: any = null;

  public downloadURL: any = null;

  public locationLng: any;
  public locationLat: any;
  public locationPostcode = '';

  public mypath: string = '';
  public desc: string = '';
  public selectedFiles: FileList;
  public currentFileUpload: UploadFile;
  public progress: { percentage: number } = { percentage: 0 };
  public id: any = null;

  private _global_items: any = null;
  private _global_itemsRef: any = null;
  public item_results: any = null;
  public imgRef: any = null;

  public company: any;
  public street_number: any;
  public route: any;
  public postal_town: any;
  public administrative_area_level_2: any;
  public administrative_area_level_1: any;
  public country: any;
  public postal_code: any;

  public customer_phone: any;
  public customer_email: any;
  public bookings_email: any;

  public notificationMessage: any = '';
  public notificationUserList: any = '';

  public Selected_QR: any = '';

  constructor(
    public _firebaseDB: AngularFireDatabase,
    private _http: HttpClient,
    private afStorage: AngularFireStorage,
    private uploadService: UploadFileService
  ) {


    this.changeComponent(null, 'dashboard');

    firebase.database().goOnline();

    try {

      firebase.database().ref('/bookings').once('value').then(function(snapshot) { return snapshot.val(); })
        .then(items => {

          this.bookings_email = items.bookings_email;

        });

    } catch (e) {
      console.error(e);
    }

    try {

      firebase.database().ref('/about').once('value').then(function(snapshot) { return snapshot.val(); })
        .then(items => {

          this.aboutUsText = items;

        });

    } catch (e) {
      console.error(e);
    }

    try {

      firebase.database().ref('/contact').once('value').then(function(snapshot) { return snapshot.val(); })
        .then(items => {

          this.customer_phone = items.customer_phone;
          this.customer_email = items.customer_email;
        });

    } catch (e) {
      console.error(e);
    }

    try {

      firebase.database().ref('/location').once('value').then(function(snapshot) { return snapshot.val(); })
        .then(items => {

          this.location = items;

          this.company = items.company;
          this.street_number = items.street_number;
          this.route = items.route;
          this.postal_town = items.postal_town;
          this.administrative_area_level_2 = items.administrative_area_level_2;
          this.country = items.country;
          this.postal_code = items.postal_code;

          this.locationLng = items.lng;
          this.locationLat = items.lat;

        });

    } catch (e) {
      console.error(e);
    }


    try {

      this._global_items_Email = this._firebaseDB.list('/CustomerEmails', ref => ref.limitToLast(30)).snapshotChanges()
        .pipe(map(actions => {
          return actions.map(action => ({ key: action.key, val: action.payload.val() }));
        }))
        .subscribe(items => {

          var icount = 0;
          var _items_Emails = [];
          _items_Emails = items.map(item => item).reverse();
          this.EmailLog = [];

          for (let i in _items_Emails) {

            this.EmailLog[icount] = [];
            this.EmailLog[icount]['text1'] = _items_Emails[i].val.text1;
            this.EmailLog[icount]['text2'] = _items_Emails[i].val.text2;
            this.EmailLog[icount]['text3'] = _items_Emails[i].val.text3;
            icount++;


          }

          //console.log(items);

        });


    } catch (e) {
      console.error(e);
    }

    try {

      this._global_items_Bookings = this._firebaseDB.list('/CustomerBookings', ref => ref.limitToLast(30)).snapshotChanges()
        .pipe(map(actions => {
          return actions.map(action => ({ key: action.key, val: action.payload.val() }));
        }))
        .subscribe(items => {

          var icount = 0;
          var _items_Bookings = [];
          _items_Bookings = items.map(item => item).reverse();
          this.BookingsLog = [];

          for (let i in _items_Bookings) {

            this.BookingsLog[icount] = [];
            this.BookingsLog[icount]['text1'] = _items_Bookings[i].val.text1;
            this.BookingsLog[icount]['text2'] = _items_Bookings[i].val.text2;
            this.BookingsLog[icount]['text3'] = _items_Bookings[i].val.text3;
            this.BookingsLog[icount]['myDate1'] = _items_Bookings[i].val.myDate1;
            this.BookingsLog[icount]['myDate2'] = _items_Bookings[i].val.myDate2;
            icount++;


          }

          //console.log(items);

        });


    } catch (e) {
      console.error(e);
    }

    try {

      this._global_items_loyaltyCards = this._firebaseDB.list('/loyaltyCards').snapshotChanges()
        .pipe(map(actions => {
          return actions.map(action => ({ key: action.key, val: action.payload.val() }));
        }))
        .subscribe(items => {

          var icount = 0;
          this._items_loyaltyCards = [];
          this._items_loyaltyCards = items.map(item => item).reverse();
          this.loyaltyCards = [];

          for (let i in this._items_loyaltyCards) {

            console.log("loyaltycards", this._items_loyaltyCards[i]);


            /*
            console.log("xxxxxxxxxxxxxxxxx");
            console.log(this._global_items[i].key);
            console.log("xxxxxxxxxxxxxxxxx");
            */



            this.loyaltyCards[icount] = [];
            this.loyaltyCards[icount]['id'] = this._items_loyaltyCards[i].key;
            this.loyaltyCards[icount]['adminref'] = this._items_loyaltyCards[i].val.adminref;
            this.loyaltyCards[icount]['offer_type'] = this._items_loyaltyCards[i].val.offer_type;
            this.loyaltyCards[icount]['reward'] = this._items_loyaltyCards[i].val.reward;
            this.loyaltyCards[icount]['stamp'] = this._items_loyaltyCards[i].val.stamp;
            this.loyaltyCards[icount]['stamp_number'] = this._items_loyaltyCards[i].val.stamp_number;
            this.loyaltyCards[icount]['code'] = this._items_loyaltyCards[i].val.code;
            this.loyaltyCards[icount]['code_redeem'] = this._items_loyaltyCards[i].val.code_redeem;
            this.loyaltyCards[icount]['edit'] = this._items_loyaltyCards[i].val.edit;
            this.loyaltyCards[icount]['is_demo'] = this._items_loyaltyCards[i].val.is_demo;
            this.loyaltyCards[icount]['is_enabled'] = this._items_loyaltyCards[i].val.is_enabled;
            this.loyaltyCards[icount]['date_start'] = this._items_loyaltyCards[i].val.date_start;
            this.loyaltyCards[icount]['date_end'] = this._items_loyaltyCards[i].val.date_end;
            this.loyaltyCards[icount]['description'] = this._items_loyaltyCards[i].val.description;
            this.loyaltyCards[icount]['icon'] = this._items_loyaltyCards[i].val.icon;
            icount++;


          }

          //console.log(items);

        });


    } catch (e) {
      console.error(e);
    }


    try {

      this._global_items_subscription = this._firebaseDB.list('/adds').snapshotChanges()
        .pipe(map(actions => {
          return actions.map(action => ({ key: action.key, val: action.payload.val() }));
        }))
        .subscribe(items => {

          var icount = 0;
          this._global_items = [];
          this._global_items = items.map(item => item).reverse();
          this.item_results = [];

          for (let i in this._global_items) {

            //console.log(this._global_items[i]);


            /*
            console.log("xxxxxxxxxxxxxxxxx");
            console.log(this._global_items[i].key);
            console.log("xxxxxxxxxxxxxxxxx");
            */

            this.item_results[icount] = [];
            this.item_results[icount]['id'] = this._global_items[i].key;
            this.item_results[icount]['image'] = this._global_items[i].val.image;
            this.item_results[icount]['header'] = this._global_items[i].val.header;
            this.item_results[icount]['text'] = this._global_items[i].val.text;
            this.item_results[icount]['code'] = this._global_items[i].val.code;
            this.item_results[icount]['edit'] = this._global_items[i].val.edit;

            if (this._global_items[i].val.date_start == '' || this._global_items[i].val.date_start === undefined || this._global_items[i].val.date_start == null) {
              var d = new Date();
              var year = d.getFullYear();
              var month = d.getMonth();
              var day = d.getDate();
              this.item_results[icount]['date_start'] = new Date(month - 1, month, day).toISOString();
            }

            if (this._global_items[i].val.date_end == '' || this._global_items[i].val.date_end === undefined || this._global_items[i].val.date_end == null) {
              var d = new Date();
              var year = d.getFullYear();
              var month = d.getMonth();
              var day = d.getDate();
              this.item_results[icount]['date_end'] = new Date(month + 2, month, day).toISOString();
            }


            this.item_results[icount]['date_start'] = this._global_items[i].val.date_start;
            this.item_results[icount]['date_end'] = this._global_items[i].val.date_end;

            icount++;


          }

          //console.log(items);

        });


    } catch (e) {
      console.error(e);
    }

  }



  public changeComponent(v, changeTo) {


    this.page_dashboard = false;
    this.page_notifications = false;
    this.page_loyalty_card = false;
    this.page_latest_offers = false;
    this.page_how_to_find_us = false;
    this.page_bookings = false;
    this.page_about_us = false;
    this.page_get_in_touch = false;
    this.page_email_log = false;
    this.page_QR = false;


    this.Selected_QR = '';

    if (changeTo == 'dashboard') {
      this.page_dashboard = true;
    } else if (changeTo == 'notifications') {
      this.page_notifications = true;
    } else if (changeTo == 'loyalty_card') {
      this.page_loyalty_card = true;
    } else if (changeTo == 'latest_offers') {
      this.page_latest_offers = true;
    } else if (changeTo == 'how_to_find_us') {
      this.page_how_to_find_us = true;
    } else if (changeTo == 'bookings') {
      this.page_bookings = true;
    } else if (changeTo == 'about_us') {
      this.page_about_us = true;
    } else if (changeTo == 'get_in_touch') {
      this.page_get_in_touch = true;
    } else if (changeTo == 'email_log') {
      this.page_email_log = true;
    } else if (changeTo == 'QR') {

      this.Selected_QR = v;
      this.page_QR = true;
    }



  }

  public GetInTouch() {

    firebase.database().ref("/contact").set({

      'customer_phone': this.customer_phone,
      'customer_email': this.customer_email

    });

  }

  public resetEverything() {

    this.removeAllNotifications();
    this.resetadds();
    firebase.database().ref("/bookings").set({

      'bookings_email': 'support@newdigitalmarketing.co.uk'

    });
    firebase.database().ref("/contact").set({

      'customer_phone': '01952 287346',
      'customer_email': 'support@newdigitalmarketing.co.uk'

    });

    var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    var DateStart = new Date(year, month - 1, day).toISOString();
    var DateEnd = new Date(year, month + 6, day).toISOString();

    firebase.database().ref("/location").set({

      'company': 'NDM Creative',
      'street_number': 'SB212',
      'route': 'BaTC Telford Innovation Campus',
      'postal_town': 'Telford',
      'administrative_area_level_2': 'Shropshire',
      'country': 'UK',
      'postal_code': 'TF2 9NT',
      'lng': '-2.4263692',
      'lat': '52.6828915'

    });

    firebase.database().ref("/CustomerEmails").remove();

    let ce1 = firebase.database().ref("/CustomerEmails").push();
    ce1.set({
      text1: "Hi do you do microblading?",
      text2: "Wont be around until the afternoon so call me then.",
      text3: "hanna@gmail.com"
    });

    let ce2 = firebase.database().ref("/CustomerEmails").push();
    ce2.set({
      text1: "Did I leave my bag at your Studio?",
      text2: "Its a Jimmy Choo pink colour",
      text3: "07xxxx",
    });

    firebase.database().ref("/CustomerBookings").remove();

    let cb1 = firebase.database().ref("/CustomerBookings").push();
    cb1.set({
      myDate1: "2018-08-20",
      myDate2: "07:22",
      text1: "Need an emergency nail repair",
      text2: "Can you please do this ASAP",
      text3: "07xxxx",
    });

    let cb2 = firebase.database().ref("/CustomerBookings").push();
    cb2.set({
      myDate1: "2018-08-20",
      myDate2: "07:22",
      text1: "Can you do mine and my childs hair next week?",
      text2: "Wont be around until Tuesday so after then.",
      text3: "frankie@hotmail.com",
    });

    firebase.database().ref("/loyaltyCards").remove();






    let lc1 = firebase.database().ref("/loyaltyCards").push();
    lc1.set({
      'adminref': 'Loyalty Card 1',
      'offer_type': 'Discount on next purchase',
      'reward': '£10 off when you spend £20 or more',
      'stamp': 'Every time you spend £10 in store',
      'stamp_number': 3,
      'code': '12345A',
      'code_redeem': 'ABCDE11',
      'edit': true,
      'is_demo': true,
      'is_enabled': 'YES',
      'date_start': DateStart,
      'date_end': DateEnd,
      'description': 'Get a £10 voucher when you collect 6 stamps (minimum spend required)',
      'icon': 'vinal'
    });

    let lc2 = firebase.database().ref("/loyaltyCards").push();
    lc2.set({
      'adminref': 'Loyalty Card 2',
      'offer_type': 'Free Item',
      'reward': 'Free 10 minute sunbed session',
      'stamp': 'Every paid sunbed session',
      'stamp_number': 6,
      'code': '12345B',
      'code_redeem': 'ABCDE22',
      'edit': false,
      'is_demo': true,
      'is_enabled': 'YES',
      'date_start': DateStart,
      'date_end': DateEnd,
      'description': 'Get a free sun bed session on completion of 6 prior sun bed sessions.',
      'icon': 'vinal'
    });

    firebase.database().ref("/about").set('Updatable content directly from your JustBeLoyal Admin website page, no app updates for the user required, just an internet connection on the phone.');

    location.reload();

  }

  public Bookings() {

    firebase.database().ref("/bookings").set({

      'bookings_email': this.bookings_email,

    });

    this.savedMessage = 'Text Saved';
    setTimeout(function() { this.savedMessage = ''; }, 6000);

  }

  public HowToFindUs() {

    firebase.database().ref("/location").set({

      'company': this.company,
      'street_number': this.street_number,
      'route': this.route,
      'postal_town': this.postal_town,
      'administrative_area_level_2': this.administrative_area_level_2,
      'country': this.country,
      'postal_code': this.postal_code,
      'lat': this.locationLat,
      'lng': this.locationLng

    });

    this.savedMessage = 'Text Saved';
    setTimeout(function() { this.savedMessage = ''; }, 6000);

  }

  public async fun_unsub() {

    this._global_items_Email.unsubscribe();
    this._global_items_loyaltyCards.unsubscribe();
    this._global_items_subscription.unsubscribe();

    firebase.database().goOffline();
    console.warn("killed everthing");
  }

  ngOnDestroy() {
    this.fun_unsub();
  }

  public SendNotifications(text) {

    try {

      this.removeAllNotifications();

      firebase.database().ref('/users').once('value').then(function(snapshot) { return snapshot.val(); })

        .then(items => {
          console.log(items);

          this.notificationUserList = items;
          console.log("oK", text);
          for (let i in this.notificationUserList) {

            if (text != '' && i != '') {
              this.setupNotification(i, text);
            }

          }

        });

      this.notificationMessage = '';
      alert("Notification Sent");



    } catch (e) {
      console.error(e);
    }

  }

  setupNotification(i, text) {

    //notificationMessage
    if (i == "bCl901aZDAbAXPQPuZNhzNZhHlD2") {
      console.log("message added", text);
      let nm = firebase.database().ref('/users/' + i + '/messages/').push();
      nm.set({

        'message': text,

      });
    }
  }

  removeAllNotifications() {
    try {

      firebase.database().ref('/users').once('value').then(function(snapshot) { return snapshot.val(); })

        .then(items => {
          console.log(items);

          this.notificationUserList = items;

          for (let i in this.notificationUserList) {

            firebase.database().ref('/users/' + i + '/messages').remove();

          }

        });

    } catch (e) {
      console.error(e);
    }

  }





  public addnewadd() {

    var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    var DateStart = new Date(year, month - 1, day).toISOString();
    var DateEnd = new Date(year, month + 6, day).toISOString();

    let newPostKey5 = firebase.database().ref('/adds').push();
    newPostKey5.set({

      'header': '',
      'text': '',
      'image': '',
      'code': '',
      'edit': true,
      'date_start': DateStart,
      'date_end': DateEnd

    });

  }
  public resetadds() {
    firebase.database().ref('/adds').remove();
    this.imgRef = null;

    var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    var DateStart = new Date(year, month - 1, day).toISOString();
    var DateEnd = new Date(year, month + 6, day).toISOString();

    let newPostKey1 = firebase.database().ref('/adds').push();
    newPostKey1.set({

      'header': 'Get £5 off any nail treament over £25',
      'text': 'Valid from 11th Sept 2018 until 2nd Oct 2018',
      'image': 'https://storage.googleapis.com/ndmapps-agents.appspot.com/static-images/justbeloyal/nailplish.jpg',
      'code': '12345ABC',
      'edit': false,
      'date_start': DateStart,
      'date_end': DateEnd

    });


    let newPostKey2 = firebase.database().ref('/adds').push();
    newPostKey2.set({

      'header': 'Free Highlights when you spend over £40',
      'text': 'Valid from 21th Sept 2018 until 2nd Oct 2018',
      'image': 'https://storage.googleapis.com/ndmapps-agents.appspot.com/static-images/justbeloyal/hairbrush.png',
      'code': 'VEDF543TEVFC',
      'edit': false,
      'date_start': DateStart,
      'date_end': DateEnd

    });

    let newPostKey3 = firebase.database().ref('/adds').push();
    newPostKey3.set({

      'header': 'Get £5 off body tanning over £35',
      'text': 'Valid from 31th Oct 2018 until 2nd Oct 2018',
      'image': 'https://storage.googleapis.com/ndmapps-agents.appspot.com/static-images/justbeloyal/beautician-spraying-tan-small.jpg',
      'code': 'DDFS243RWVFC',
      'edit': false,
      'date_start': DateStart,
      'date_end': DateEnd

    });



  }

  public AboutUsedit(text) {


    firebase.database().ref("/about").set(this.aboutUsText);

    this.savedMessage = 'Text Saved';
    setTimeout(function() { this.savedMessage = ''; }, 6000);

  }



  public editme(id, header, text, code, image) {

    this.imgRef = null;

    firebase.database().ref('/adds/' + id).update({

      'edit': true

    });

  }

  public saveme(id, header, text, code, image, date_start, date_end) {


    this.imgRef = null;
    firebase.database().ref('/adds/' + id).update({

      'header': header,
      'text': text,
      'image': image,
      'code': code,
      'edit': false,
      'date_start': date_start,
      'date_end': date_end

    });

  }

  public selectFile(event) {
    this.selectedFiles = event.target.files;
    console.log("this.selectedFiles", this.selectedFiles);
  }

  public upload(id) {
    const file = this.selectedFiles.item(0);
    this.selectedFiles = undefined;
    //console.log("file",file.name)
    this.currentFileUpload = new UploadFile(file);


    this.imgRef = this.uploadService.pushFileToStorage(this.currentFileUpload, this.progress, file.name, id);

    firebase.database().ref('/adds/' + id).update({

      image: this.imgRef

    });

    this.selectedFiles = null;
    this.progress.percentage = 0;
    this.currentFileUpload = null;
    this.selectedFiles = null;

  }

  deletemeoffer(id) {
    firebase.database().ref('/adds/' + id).remove();
  }

  editLoyaltyCard(id) {


    firebase.database().ref('/loyaltyCards/' + id).update({

      'edit': true

    });


  }

  saveLoyaltyCard(id, adminref, offer_type, reward, stamp, stamp_number, code, code_redeem, is_demo, date_start, date_start_time, date_end, date_end_time, description, icon, is_enabled) {

    firebase.database().ref('/loyaltyCards/' + id).update({
      'adminref': adminref,
      'offer_type': offer_type,
      'reward': reward,
      'stamp': stamp,
      'stamp_number': stamp_number,
      'code': code,
      'code_redeem': code_redeem,
      'edit': false,
      'is_demo': true,
      'is_enabled': is_enabled,
      'date_start': date_start,
      'date_end': date_end,
      'description': description,
      'icon': icon
    });

  }

  removeLoyaltyCard(id) {

    if (confirm('Are you sure you want permanently delete this?')) {
      firebase.database().ref("/loyaltyCards/" + id).remove();
    }

  }

  addLoyaltyCard() {


    var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    var DateStart = new Date(year, month - 1, day).toISOString();
    var DateEnd = new Date(year, month + 6, day).toISOString();


    let lc3 = firebase.database().ref("/loyaltyCards").push();
    lc3.set({
      'adminref': 'Your new Loyalty Card',
      'offer_type': 'Discount on next purchase',
      'reward': '£10 off when you spend £20 or more',
      'stamp': 'Every time you spend £10 in store',
      'stamp_number': 3,
      'code': '12345',
      'code_redeem': 'ABCDE1',
      'edit': true,
      'is_demo': true,
      'is_enabled': 'NO',
      'date_start': DateStart,
      'date_end': DateEnd,
      'description': 'Get a £10 voucher when you collect 6 stamps (minimum spend required)',
      'icon': 'vinal'
    });
  }



}
