import { Injectable } from '@angular/core';

import { UploadFile } from './upload-file';

import * as firebase from 'firebase';
@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  imgRef: any = null;
  imgRefURL: any = null;

  constructor() { }


  pushFileToStorage(PolicyNewUploadClass: UploadFile, progress: { percentage: number }, filename, mypath) {



    //filename = encodeURI(filename);
    //mypath = encodeURI(mypath);

    //YYYYMMDDhhmmss
    var fname = new Date().getTime();
    //console.log(fname);
    var fname2 = filename.split('.').pop();
    var fname3 = String(fname) + '.' + String(fname2);

    this.imgRef = 'adds/' + mypath + '/' + fname3;
    var url = 'https://storage.googleapis.com/ndm-apps-justbeloyal.appspot.com/' + this.imgRef;

    this.imgRefURL = 'https://storage.googleapis.com/ndm-apps-justbeloyal.appspot.com/' + this.imgRef;


    const storageRef = firebase.storage().ref();
    const uploadTask = storageRef.child(this.imgRef).put(PolicyNewUploadClass.file);

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        // in progress
        const snap = snapshot as firebase.storage.UploadTaskSnapshot;
        progress.percentage = Math.round((snap.bytesTransferred / snap.totalBytes) * 100);
      },
      (error) => {
        // fail
        console.log(error);
      },
      () => {

      }
    );

    return this.imgRefURL;
  }

}
