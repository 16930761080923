import { Component, OnInit } from '@angular/core';
import { GlobalFunctionsService } from './service/global-functions.service';

import { LatestOffersComponent } from './components/latest-offers/latest-offers.component';
import { LoyaltyCardComponent } from './components/loyalty-card/loyalty-card.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { HowToFindUsComponent } from './components/how-to-find-us/how-to-find-us.component';
import { BookingsComponent } from './components/bookings/bookings.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { GetInTouchComponent } from './components/get-in-touch/get-in-touch.component';
import { EmailLogComponent } from './components/email-log/email-log.component';
import { QRCodePrintComponent } from './components/qrcode-print/qrcode-print.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(public _f:GlobalFunctionsService)  { }


}
