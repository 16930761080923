
import { NgModule, Component, Injectable, OnInit, OnDestroy } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
/*
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireModule } from '@angular/fire';
*/
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';

import { RequestOptions, Request, Headers } from '@angular/http';

import { QRCodeModule } from 'angularx-qrcode';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';

import { UploadFileService } from './service/upload/upload-file.service';
import { UploadFile } from './service/upload/upload-file';
import { GlobalFunctionsService } from './service/global-functions.service';
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';

import { LatestOffersComponent } from './components/latest-offers/latest-offers.component';
import { LoyaltyCardComponent } from './components/loyalty-card/loyalty-card.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { HowToFindUsComponent } from './components/how-to-find-us/how-to-find-us.component';
import { BookingsComponent } from './components/bookings/bookings.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { GetInTouchComponent } from './components/get-in-touch/get-in-touch.component';
import { EmailLogComponent } from './components/email-log/email-log.component';
import { QRCodePrintComponent } from './components/qrcode-print/qrcode-print.component';

const appRoutes: Routes = [
  { path: 'qr', component: QRCodePrintComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    LatestOffersComponent,
    LoyaltyCardComponent,
    AboutUsComponent,
    HowToFindUsComponent,
    BookingsComponent,
    NotificationsComponent,
    DashboardComponent,
    GetInTouchComponent,
    EmailLogComponent,
    QRCodePrintComponent
  ],
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    ),
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    FormsModule,
    HttpClientModule,
    HttpModule,
    AngularFireStorageModule,
    FormsModule,
    AngularFontAwesomeModule,
    AngularDateTimePickerModule,
    QRCodeModule
  ],
  providers: [UploadFileService, GlobalFunctionsService, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
