import { Component, OnInit } from '@angular/core';
import { GlobalFunctionsService } from '../../service/global-functions.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  public notificationMessage:any;

  constructor(public _f:GlobalFunctionsService)  { }

  ngOnInit() {
  }

}
