
export const environment = {
  production: false,
  firebase : {
    apiKey: "AIzaSyDoI72O0b_kMoZhLplhM5ea2m4Qjaxi3KU",
    authDomain: "ndm-apps-justbeloyal.firebaseapp.com",
    databaseURL: "https://ndm-apps-justbeloyal.firebaseio.com",
    projectId: "ndm-apps-justbeloyal",
    storageBucket: "ndm-apps-justbeloyal.appspot.com",
    messagingSenderId: "818973550569"
 },

googleMapsKey: 'AIzaSyCU_OTOwA_OjInqSJ5tOuIjSb55YH5LO1U'

};
