import { Component, OnInit } from '@angular/core';
import { GlobalFunctionsService } from '../../service/global-functions.service';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss']
})
export class BookingsComponent implements OnInit {

  constructor(public _f:GlobalFunctionsService)  { }

  ngOnInit() {
  }

}
