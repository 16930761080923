import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';

import { GlobalFunctionsService } from '../../service/global-functions.service';

import { Http } from '@angular/http';
import 'rxjs/add/operator/map';


declare var google: any;

@Component({
  selector: 'app-how-to-find-us',
  templateUrl: './how-to-find-us.component.html',
  styleUrls: ['./how-to-find-us.component.scss']
})
export class HowToFindUsComponent implements OnInit {

  @ViewChild('mapContainer') mapContainer: ElementRef;
  public map: any;
  public locationLat: any;
  public locationLng: any;
  public geocoder: any;
  public x: any;
  public formatted_address: any;
  public street_number: any;
  public route: any;
  public postal_town: any;
  public administrative_area_level_2: any;
  public administrative_area_level_1: any;
  public country: any;
  public postal_code: any;
  public latLng: any;

  constructor(public _f: GlobalFunctionsService, public http: Http) { }

  public ngOnDestroy() {




  }

  ngOnInit() {


    if (this._f.locationLat == '' || this._f.locationLat == null || this._f.locationLat == undefined) {
      this._f.locationLat = 52.708435;
    }

    if (this._f.locationLng == '' || this._f.locationLng == null || this._f.locationLng === undefined) {
      this._f.locationLng = -2.757590;
    }


    this.locationLat = this._f.locationLat;
    this.locationLng = this._f.locationLng;

    this.displayGoogleMap();
    this.initListenforchanges();

  }


  initListenforchanges() {

    this._f.locationLat = this.locationLat;
    this._f.locationLng = this.locationLng;


    //setTimeout(()=> this.initListenforchanges(), 3000);

    console.log(this.locationLat + ', ' + this.locationLng);
  }

  handleLocationError(browserHasGeolocation: any, infoWindow: any, pos: any) {
    infoWindow.setPosition(pos);
    infoWindow.setContent(browserHasGeolocation ?
      'Error: The Geolocation service failed.' :
      'Error: Your browser doesn\'t support geolocation.');
    infoWindow.open(this.map);
  }

  displayGoogleMap() {

    this.latLng = new google.maps.LatLng(this.locationLat, this.locationLng);

    let mapOptions = {
      center: this.latLng,
      disableDefaultUI: true,
      zoom: 12,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    }
    this.map = new google.maps.Map(this.mapContainer.nativeElement, mapOptions);

    //-------------------------------------------------------------
    var position = new google.maps.LatLng(this.locationLat, this.locationLng);
    var dogwalkMarker = new google.maps.Marker(
      {
        position: position,
        title: "Your Business Location",
      }
    );
    dogwalkMarker.setMap(this.map);

    //----------  ---------------------------------------------------

    google.maps.event.addListener(this.map, "click", (event) => {
      var latitude = event.latLng.lat();
      var longitude = event.latLng.lng();

      dogwalkMarker.setPosition(new google.maps.LatLng(latitude, longitude));

      this.locationLat = '';
      this.locationLng = '';
      this.locationLat = latitude;
      this.locationLng = longitude;

      //console.log(latitude + ', ' + longitude);

      this.geocoder = new google.maps.Geocoder;
      var latlng = { lat: parseFloat(this.locationLat), lng: parseFloat(this.locationLng) };
      this.x = this.geocoder.geocode({ 'location': latlng }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {

            this.formatted_address = results[0].formatted_address
            console.log(results[0]);
            var add = results[0].address_components;

            this.street_number = '';
            this.route = '';
            this.postal_town = '';
            this.administrative_area_level_2 = '';
            this.administrative_area_level_1 = '';
            this.country = '';
            this.postal_code = '';


            for (var i in add) {

              if (add[i].types[0] == 'street_number') {
                console.log(add[i].types[0], add[i].long_name);
                this.street_number = add[i].long_name;
              }
              if (add[i].types[0] == 'route') {
                console.log(add[i].types[0], add[i].long_name);
                this.route = add[i].long_name;
              }
              if (add[i].types[0] == 'postal_town') {
                console.log(add[i].types[0], add[i].long_name);
                this.postal_town = add[i].long_name;
              }
              if (add[i].types[0] == 'administrative_area_level_2') {
                console.log(add[i].types[0], add[i].long_name);
                this.administrative_area_level_2 = add[i].long_name;
              }
              if (add[i].types[0] == 'administrative_area_level_1') {
                console.log(add[i].types[0], add[i].long_name);
                this.administrative_area_level_1 = add[i].long_name;
              }
              if (add[i].types[0] == 'country') {
                console.log(add[i].types[0], add[i].long_name);
                this.country = add[i].long_name;
              }
              if (add[i].types[0] == 'postal_code') {
                console.log(add[i].types[0], add[i].long_name);
                this.postal_code = add[i].long_name;
              }

            }



            return results[0];

          } else {
            console.log("nope");
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }

      });


    });

    //console.log(this.map);



    //-------------------------------------------------------------




  }
  useAddress() {

    this._f.street_number = '';
    this._f.route = '';
    this._f.postal_town  = '';
    this._f.administrative_area_level_2 = '';
    this._f.country  = '';
    this._f.postal_code  = '';
    this._f.locationLat  = '';
    this._f.locationLng  = '';

    this._f.street_number = this.street_number;
    this._f.route = this.route;
    this._f.postal_town = this.postal_town;
    this._f.administrative_area_level_2 = this.administrative_area_level_2;
    this._f.country = this.country;
    this._f.postal_code = this.postal_code;
    this._f.locationLat = this.locationLat;
    this._f.locationLng = this.locationLng;

  }
  onChange(event, results) {

    console.log("xxxxxxxx");
    console.log(this.locationLat, this.locationLng);
    console.log(this.formatted_address);
    console.log(results);
    console.log(this.geocoder.geocode);
    //console.log(this.map);
    //console.log(event);
    //console.log(this.map.center);
    //console.log(this.map.center.lat());
    //console.log(this.map.center.lng());

    //console.log(this.geocoder);
    console.log("xxxxxxxx");
  }

  geocodeLatLng() {




  }
  addMarkersToMap(x, y) {


  }

}
