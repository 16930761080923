import { Component, OnInit } from '@angular/core';
import { GlobalFunctionsService } from '../../service/global-functions.service';

@Component({
  selector: 'app-get-in-touch',
  templateUrl: './get-in-touch.component.html',
  styleUrls: ['./get-in-touch.component.scss']
})
export class GetInTouchComponent implements OnInit {

  constructor(public _f: GlobalFunctionsService) { }

  ngOnInit() {
  }

}
