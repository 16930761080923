import { Component, OnInit } from '@angular/core';
import { GlobalFunctionsService } from '../../service/global-functions.service';
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
import { DatePipe } from '@angular/common';
import { HttpParams, HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
const httpOptions = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin': '*'
  })
};
import { QRCodeModule } from 'angularx-qrcode';

@Component({
  selector: 'app-qrcode-print',
  templateUrl: './qrcode-print.component.html',
  styleUrls: ['./qrcode-print.component.scss']
})
export class QRCodePrintComponent implements OnInit {

 public myAngularxQrCode: string = null;

 date: Date;
 settings: any;

 constructor(public _f: GlobalFunctionsService,private datePipe: DatePipe) {
   

       let created: string = 'Thu Jul 26 2018 15:30:00 GMT-0500';
       created = created.substring(0, created.indexOf(' GMT'));
       this.date = new Date(created);
       console.log(this.date);
       created = this.datePipe.transform(this.date, 'yyyy-MM-ddTHH:mm');
       console.log(created)

       //this.date = new Date();
       this.settings = {
         bigBanner: true,
         timePicker: true,
         displayFormat: 'D[,] MMMM  YYYY',
         barTitleFormat: 'D[,] MMMM  YYYY',
         defaultOpen: false,
         minYear: 1970,
         maxYear: 2030,
       }

     this.myAngularxQrCode = 'Your QR code data string';

  }

  ngOnInit() {
  }

}
